<template>
  <div class="add-state-announcement add-template">
    <g-background>
      <page-header>
        <h1>{{ translations.tcAddCommitteeAnnouncement }}</h1>
      </page-header>
      <page-body>
        <div class="page-navigation">
          <div class="nav-item nav-item-1" :class="{ active: page > 0 }">
            <div class="icon">
              <audience-icon />
            </div>
            <div class="text">{{ translations.tcAudience }}</div>
          </div>
          <div class="sep" :class="{ active: page > 1 }"></div>
          <div class="nav-item nav-item-2" :class="{ active: page > 1 }">
            <div class="icon">
              <message-icon />
            </div>
            <div class="text">{{ translations.tcEnterMessage }}</div>
          </div>
          <div class="sep" :class="{ active: page > 2 }"></div>
          <div class="nav-item nav-item-4" :class="{ active: page > 2 }">
            <div class="icon">
              <review-icon />
            </div>
            <div class="text">{{ translations.tcReviewAndSubmit }}</div>
          </div>
        </div>
      </page-body>
      <hr />
      <b-form @submit.prevent v-if="page === 1" class="view-1 g-mb-2">
        <h2 class="h-style-1 mb-3">
          {{ translations.tcAudience }}
          <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcAudience">
            <i-tooltip />
          </span>
        </h2>
        <div>
          <div class="checkbox-row" v-if="addableAreas.length > 0">
            <GeographicAreas
              :geo_type="`committee`"
              :area_prop="addableCommittees"
              @checkboxChanged="handleCheckboxChange()"
            ></GeographicAreas>
          </div>
        </div>
        <span>
          <h2 class="h-style-1 mb-3 mtb30">{{ translations.tcCommitteeMembers }}</h2>
          <div class="checkbox-row">
            <div v-for="(committeeMemberList, dx) in committeesList" :key="dx">
              <GeographicAreas :area_prop="committeeMemberList" :geo_type="`individual`" />
            </div>
          </div>
        </span>
      </b-form>
      <b-form @submit.prevent v-if="page === 2" class="view-2 g-mb-2">
        <AnnouncementTemplate
          :form="form"
          :nextButtonText="translations.tcNext"
          @announcement_validated="handleNextClick"
          @announcement_cancel="handleCancelClick"
          @backClick="page--"
        />
      </b-form>
      <div v-if="page === 3">
        <AnnouncementReview
          :audienceDistribution="audienceDistribution"
          :selectedCommittees="selectedCommittees"
        ></AnnouncementReview>
      </div>
      <section v-if="page !== 2" class="form-buttons">
        <b-button variant="primary" class="mr-3" v-if="page > 1 && page < 3" @click="page--">
          {{ translations.tcBack }}
        </b-button>
        <b-button variant="primary" class="mr-3" v-if="page < 3" @click="handleNextClick()">
          {{ translations.tcNext }}
        </b-button>
        <b-button variant="tertiary" v-if="page < 3" @click="handleCancelClick()">
          {{ translations.tcCancel }}
        </b-button>
        <b-button variant="primary" class="mr-3" v-if="page === 3" @click="handleSubmitClick()">
          {{ translations.tcSubmit }}
        </b-button>
        <b-button variant="tertiary" v-if="page === 3" @click="page = 1">
          {{ translations.tcEdit }}
        </b-button>
      </section>
    </g-background>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AnnouncementReview from '@/components/AnnouncementReview.vue'
import AnnouncementTemplate from '@/components/AnnouncementTemplate.vue'
import audienceIcon from '@/assets/svgs/audience-icon.vue'
import constantData from '@/json/data.json'
import gBackground from '@/components/gBackground.vue'
import GeoDis from '@/assets/svgs/geographic-distribution-icon.vue'
import GeographicAreas from '@/components/GeographicAreas.vue'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import messageIcon from '@/assets/svgs/message-icon.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import reviewIcon from '@/assets/svgs/review-icon.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-committee-announcement',
  mixins: [translationMixin],
  data() {
    return {
      addableAreas: [],
      addableCommitteeTypes: {
        'All Committees': 'tcAllCommittees',
        Headquarters: 'tcHeadquarters',
      },
      committeeList: [],
      form: {},
      localCommitteesList: [],
      page: 1,
      translations: {},
      selectedCommittees: [],
    }
  },
  watch: {},
  async created() {
    try {
      await Promise.all([
        await this.setLoadingStatus(true),
        await this.loadUp(),
        await this.getViewTranslations(),
        await this.getComponentTranslations('common.committee-positions', 'common.committee-types'),
      ]).then((results) => {
        this.stripReadableText(results[3], 'text')
        const translatedText = {
          ...results[2],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.constructCommitteeList()
        this.translateAudienceTitle()
        this.addableAreas = this.addableCommittees.areas.map((aa) => {
          const commGuid = aa.value && aa.value !== constantData.empty_guid ? aa.value : ''
          aa.text = this.translations.common['committee-types'].hasOwnProperty(commGuid)
            ? this.translations.common['committee-types'][commGuid]
            : this.addableCommitteeTypes.hasOwnProperty(aa.text) // 2nd ternary to catch text-based committee names
            ? this.translations[this.addableCommitteeTypes[aa.text]]
            : aa.text
          this.selectedUserCommittees.areas.map((suca) => {
            aa.selected = aa.value === suca.value
          })
        })
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      addableCommittees: 'announcements/addableCommittees',
      announcementForm: 'announcements/announcementForm',
      committeeCountries: 'announcements/committeeCountries',
      committeeIndividuals: 'announcements/committeeIndividuals',
      committeesList: 'announcements/committeesList',
      committeesListWithSomeSelected: 'announcements/committeesListWithSomeSelected',
      committeesListWithSomeSelectedAlt: 'announcements/committeesListWithSomeSelectedAlt',
      demograph: 'user/demograph',
      prefCulture: 'user/userPreferredCulture',
      preservedListSelections: 'announcements/preservedListSelections',
      selectedAllUserCommittees: 'announcements/selectedAllUserCommittees',
      selectedUserCommittees: 'announcements/selectedUserCommittees',
      userCommittees: 'announcements/userCommittees',
      userId: 'user/userId',
    }),
    audienceDistribution() {
      let aud_dist = !this.committeeCountries.areas
        ? this.committeesListWithSomeSelectedAlt
        : [...this.committeesListWithSomeSelected(['aud', 'mbr']), ...this.committeeCountries]
      return aud_dist
    },
    checkedCommitteeMembers() {
      return this.committeesList.map((cl) => {
        return cl.areas.filter((cla) => cla.selected === true)
      }).length
    },
  },
  methods: {
    ...mapActions({
      addUpdateAnnouncementMessage: 'announcements/addUpdateAnnouncementMessage',
      applyPreservedList: 'announcements/applyPreservedList',
      getAddableCommittees: 'announcements/getAddableCommittees',
      getCommitteeMembers: 'announcements/getCommitteeMembers',
      preserveListSelections: 'announcements/preserveListSelections',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMessagePackageAudience: 'announcements/setMessagePackageAudience',
      setMessagePackageGeography: 'announcements/setMessagePackageGeography',
      setMessagePackageMessage: 'announcements/setMessagePackageMessage',
    }),
    constructCommitteeList() {
      if (!constantData) return false
      constantData['super_roles'].forEach(
        (committee) => (this.committeeList[committee.key.toLowerCase()] = committee.name)
      )
    },
    async handleCancelClick() {
      this.$router.push('/get-connected/stay-informed/announcements')
    },
    async handleNextClick() {
      if (this.page === 1) {
        if (this.selectedCommittees.length === 0) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcOneOrMoreCommitteesMustBeSelected,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
        } else if (this.checkedCommitteeMembers === 0) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcOneOrMoreCommitteeMembersMustBeSelected,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
        }
      }
      this.page++
      if (this.page === 3) {
        document.documentElement.scrollTop = 0
      }
    },
    async loadUp() {
      await this.getAddableCommittees()
      await (async () => {
        this.form.aud_array = this.announcementForm.aud_array
        this.form.msg_message_id = this.announcementForm.msg_message_id
        this.form.msg_member_type = this.announcementForm.msg_member_type
        this.form.notification = this.announcementForm.notification
        this.form.title = this.announcementForm.title
        this.form.expires = this.announcementForm.expires
        this.form.message = this.announcementForm.message
        this.form.recipients =
          this.announcementForm.msg_member_type === 'a'
            ? 'Auxiliary'
            : this.announcementForm.msg_member_type === 'b'
            ? 'Both'
            : 'Gideon'
        this.form.fileArray = this.announcementForm.fileArray
        this.form.tempFileArray = this.announcementForm.tempFileArray
        this.presetDate =
          !this.announcementForm.expires || this.announcementForm.expires.toString().includes('0001')
            ? null
            : this.announcementForm.expires
      })()
      if (this.announcementForm.editing && !!this.announcementForm.mbr_array) {
        await this.populateSelectedCommittees()
        this.announcementForm.mbr_array.map((ma) => {
          this.committeesList.map((cls) => {
            let mbr = cls.areas.find((cla) => cla.value === ma.key)
            if (!!mbr) {
              mbr.selected = true
            }
          })
        })
      }
    },
    async handleCheckboxChange() {
      try {
        let sck = await this.populateSelectedCommittees()
        await Promise.all([
          this.setLoadingStatus(true),
          await this.preserveListSelections(),
          await this.getCommitteeMembers(sck, null),
          await this.applyPreservedList(),
        ]).then(() => {
          this.translateCommitteesList()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async populateSelectedCommittees() {
      let selectedCommitteeKeys = await this.selectedUserCommittees.areas.map((sk) => sk.value)
      // selecting 'all committees' needs to be the same as selecting them all individually
      if (
        !!selectedCommitteeKeys &&
        selectedCommitteeKeys.length === 1 &&
        selectedCommitteeKeys[0] === constantData.empty_guid
      ) {
        selectedCommitteeKeys = await this.selectedAllUserCommittees.areas.map((sk) => sk.value)
        this.selectedCommittees = this.selectedAllUserCommittees
      } else {
        this.selectedCommittees = this.selectedUserCommittees
      }
      return selectedCommitteeKeys
    },
    async handleSubmitClick() {
      try {
        this.setLoadingStatus(true)
        await this.setMessagePackageMessage({
          user: this.userId,
          sendername: this.demograph.informal_name + ' ' + this.demograph.last_name,
          senderemail: this.demograph.email,
        })
        await (async () => {
          this.selectedCommittees.areas.map((sca, ix) => {
            this.setMessagePackageGeography({
              location: sca.value,
              location_name: sca.text,
              type: 'Committees',
              user: this.userId,
              ordinal: ix,
            })
          })
        })()
        await (async () => {
          this.committeesList.forEach((committee) => {
            committee.areas.map((ci, cix) => {
              if (ci.selected) {
                this.setMessagePackageAudience({
                  audience: ci.value + '-' + committee.key,
                  type: 'Members',
                  user: this.userId,
                  ordinal: cix,
                })
              }
            })
          })
        })()
        await (async () => {
          if (!!this.committeeCountries.areas) {
            this.committeeCountries.areas.map((ci, cix) => {
              if (ci.selected) {
                this.setMessagePackageAudience({
                  audience: ci.value,
                  type: 'Countries',
                  user: this.userId,
                  ordinal: cix,
                })
              }
            })
          }
        })()
        let add_update_result = await this.addUpdateAnnouncementMessage({
          include: false,
          membertype: this.announcementForm.msg_member_type,
          messageid: this.announcementForm.msg_message_id,
        })
        this.setLoadingStatus(false)
        this.$swal({
          icon: add_update_result ? 'success' : 'error',
          text: add_update_result
            ? this.translations.tcAnnouncementSaved
            : this.translations.tcErrorAnnouncementNotSaved,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then(() => {
          if (add_update_result) {
            this.$router.push('/get-connected/stay-informed/announcements')
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    translateAudienceTitle() {
      const titleKey = 'tc' + this.removeSpacesAndSpecialChars(this.addableCommittees.title)
      return (this.addableCommittees.title = this.translations.commonText['committee-types'].hasOwnProperty(titleKey)
        ? this.translations.commonText['committee-types'][titleKey]
        : this.addableCommittees.title)
    },
    translateCommitteesList() {
      if (!this.translations) return false

      this.committeesList.forEach((committee, idx) => {
        // Translate Committee Member Positions
        this.committeesList[idx].areas = committee.areas.map((position) => {
          const positionText =
            'tc' + this.removeSpacesAndSpecialChars(position.office ? position.office : position.text)
          const translatedText = this.translations.commonText['committee-positions'].hasOwnProperty(positionText)
            ? this.translations.commonText['committee-positions'][positionText]
            : position.office

          let updatedPosition = { ...position, office: translatedText }

          if (position && !position.office) {
            updatedPosition = { ...position, text: translatedText }
          } // needed for 'All Members'
          return updatedPosition
        })

        // Translate Committee Heading (IE, Headquarters Committee Members)
        const commGuid = committee.key || ''
        this.committeesList[idx].title = this.translations.common['committee-types'].hasOwnProperty(commGuid)
          ? this.translations.common['committee-types'][commGuid]
          : committee.title
      })
    },
  },
  components: {
    AnnouncementTemplate: AnnouncementTemplate,
    AnnouncementReview: AnnouncementReview,
    audienceIcon: audienceIcon,
    gBackground: gBackground,
    GeoDis: GeoDis,
    GeographicAreas: GeographicAreas,
    iCalendar: iCalendar,
    iTooltip: iTooltip,
    messageIcon: messageIcon,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    reviewIcon: reviewIcon,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.calendar svg {
  margin-right: 5px;
}
.mtb30 {
  margin: 30px 0;
}
.committee-dl {
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 0;
}
.add-template {
  @include breakpoint(sm) {
    .page-header {
      text-align: center;
      h1 {
        font-size: 42px;
      }
    }
  }
  .button-group {
    .btn-primary {
      border: 1px solid #003946;
      text-transform: uppercase !important;
    }
  }
  .g-background {
    margin: 90px auto 104px;
  }
  .form-section {
    font-size: 22px;
    font-weight: bold;
    display: block;
    line-height: 44px;
    letter-spacing: 0.73px;
    text-transform: uppercase;
    color: #636363;
    margin-bottom: 10px;
  }
  .form-block {
    .form-element {
      margin-bottom: 10px;

      &.form-attachment {
        margin-bottom: 60px;
        margin-top: 45px;
        @include breakpoint(sm) {
          margin-top: 25px;
        }
      }
    }
  }
  .form-control {
    height: auto;
    font-weight: normal;
    text-transform: none;
    line-height: 25px;
    padding: 10px 20px;
    border-color: #636363;
    color: #636363;
  }
  .custom-checkbox {
    padding-left: 34px;
    line-height: 24px;
    margin-bottom: 10px;
    label {
      font-size: 16px;
      &:before {
        top: 0;
        left: -34px;
        border-radius: 0;
        width: 24px;
        height: 24px;
        border-color: #636363;
      }
      &:after {
        top: 0;
        left: -34px;
        height: 24px;
        width: 24px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #003946;
    }
  }
  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        width: 100%;
        margin-bottom: 15px !important;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

// 1060
// 410
// ----
// 650

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.add-state-announcement {
  .g-mb-1 {
    margin-bottom: 42px;
  }
  .g-mb-2 {
    margin-bottom: 60px;
    @include breakpoint(sm) {
      margin-bottom: 45px;
    }
  }
  .i-tooltip {
    vertical-align: super;
    svg path {
      fill: #000 !important;
    }
  }
  .view-1 {
    @include breakpoint(sm) {
      .btn-group {
        width: 100%;
      }
    }
  }
  .view-2 {
    .custom-control-inline {
      display: flex;
      margin-left: 30px;
      margin-bottom: 0;
      align-items: center;
      @include breakpoint(sm) {
        margin-left: 0;
      }
    }
    @include breakpoint(sm) {
      .btn-group {
        flex-wrap: wrap;
        width: 100%;
        .btn {
          width: 50%;
          flex: 0 1 auto;
          padding: 12px 5px;
        }
      }
      .member-type {
        flex-wrap: wrap;
        .btn-group {
          margin-bottom: 30px;
          @include breakpoint(sm) {
            // margin-bottom: ;
          }
          .btn {
            width: 33%;
          }
        }
      }
    }
  }
  .view-3 {
    input::placeholder {
      text-transform: uppercase;
      color: #636363;
      font-weight: bold;
    }
  }
  .view-4 {
    .col {
      display: block;
    }
    .col-a {
      max-width: 60%;
      flex-basis: 60%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 30px;
      }
    }
    .col-b {
      max-width: 40%;
      flex-basis: 40%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    section {
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 22px;
      font-size: 16px;
      line-height: 22px;
    }
    .form-attachment {
      margin-bottom: 10px;
    }
    .calendar {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
        path {
          fill: #636363 !important;
        }
      }
    }
  }
  .send-notifications {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .form-attachment {
    .attachment-list {
      margin-bottom: 20px;
      span {
        margin: 0 30px 0 10px;
        color: #1e68fb;
      }
      svg:last-of-type {
        width: 14px;
        height: 14px;
      }
      @include breakpoint(sm) {
        .btn {
          width: auto !important;
        }
      }
    }
  }
}
.page-navigation {
  display: flex;
  align-items: center;
  padding: 30px 80px 100px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px 0 30px;
  }
  .nav-item {
    position: relative;
    @include breakpoint(sm) {
      width: 100%;
    }
    .icon {
      svg {
        fill: #8c8c8c !important;
      }
    }
    &.active {
      .icon {
        background-color: $secondary-color;
        svg {
          fill: #000000 !important;
        }
      }
      .text {
        color: #000;
      }
    }
  }
  .sep {
    height: 9px;
    flex: 1 1 auto;
    background-color: #ccc;
    &.active {
      background-color: $secondary-color;
    }
    @include breakpoint(sm) {
      position: relative;
      height: 30px;
      width: 9px;
      flex: 0 0 auto;
      left: 27px;
      transform: translateX(-50%);
    }
  }
  .icon {
    position: relative;
    height: 54px;
    width: 54px;
    flex: 0 0 auto;
    background-color: #ccc;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    display: block;
    position: absolute;
    width: 140px;
    top: 100%;
    left: 50%;
    margin-top: 13px;
    transform: translateX(-50%);
    color: #8c8c8c;
    font-family: $open-sans;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    @include breakpoint(sm) {
      width: calc(100% - 70px);
      left: 70px;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
.checkbox-row {
  display: flex;
  flex-wrap: wrap;
  .checkbox-block {
    min-width: 275px;
    margin-right: 20px;
    padding: 24px 20px 35px 30px;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  .bg-block-h {
    display: flex;
    margin-bottom: 15px;
    h3 {
      flex: 1 1 auto;
      margin: 0;
      color: $gray-200;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: initial;
      line-height: 22px;
      text-transform: none;
    }
    .toggle {
      height: 25px;
      width: 25px;
      flex: 0 0 auto;
      border: 2px solid $gray-200;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 2px;
        border-radius: 2px;
        transform: rotate(45deg);
        transform-origin: bottom;
        background-color: $gray-200;
        right: -1px;
        position: relative;
      }
      &:after {
        left: -1px;
        right: auto;
        transform: rotate(-45deg);
      }
    }
  }
  label {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
